import React, { Suspense, useState, useEffect } from 'react';

import cn from 'classnames';

import NavContext from 'contexts/navigation';
import { usePage } from 'contexts/page';

import useCurrentRoutePage from 'hooks/useCurrentRoutePage';

import 'scss/skin/grey-mass.scss';

const Navigational: React.FC = ({ children }) => {
    const [navData, setNavData] = useState({});
    const { setPage } = usePage();

    const currentPage = useCurrentRoutePage();

    useEffect(() => {
        setPage(currentPage);
    }, [currentPage]);

    function setPageClassName(className): void {
        setNavData({
            ...navData,
            pageClassName: className,
        });
    }

    const ctx = {
        setPageClassName,
    };

    return (
        <NavContext.Provider value={ctx}>
            <div className="branding-expander" />
            <div className={cn(
                'page',
                currentPage && `${currentPage}-page`,
                {
                    'page_no-margin': window.isGamecenter,
                    'page_transparent-background': true,
                },
            )}
            >
                <Suspense fallback={null}>
                    {children}
                </Suspense>
            </div>
        </NavContext.Provider>
    );
};

export default React.memo(Navigational);
