import React, { useContext, useEffect, useMemo } from 'react';

import { AnalyticsProvider } from '@vkplay/analytics';
import AppShell from '@vkplay/eva';
import { isCorpUser } from '@vkplay/shared';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';

import { withBanners, useBanners } from 'contexts/banners';
import DeviceContext from 'contexts/device';
import { usePage } from 'contexts/page';
import { UpdateCounterProvider } from 'contexts/updateCounter';

import Router from 'routers/media.vkplay.ru/Router';
import getUserConsent from 'utils/getUserConsent';

import Banner from 'atoms/Banner';
import ConditionalRenderer from 'atoms/ConditionalRenderer';
import ScrollableWrapper from 'atoms/ScrollableWrapper';

import useDeviceType from 'hooks/useDeviceType';

import getProfile from './api/v3/getProfile';
import AuthContext from './contexts/auth';
import initJsonLd from './json-ld';

import type { MouseEvent } from 'react';

const AppVKP: React.FC = () => {
    const { page } = usePage();
    const { user, setUser, setUnauthorized } = useContext(AuthContext);

    const {
        addBannersDisableCondition,
    } = useBanners();

    const history = useHistory();

    useEffect(() => {
        addBannersDisableCondition('is-gc-app', window.isGamecenter);
        addBannersDisableCondition('is-mg-app', window.isMyGamesApp);
        addBannersDisableCondition('is-stb-app', window.isSTB);
    }, []);

    const {
        isMobile, isDesktop, windowWidth, isIOS,
    } = useDeviceType();

    useEffect(() => {
        initJsonLd.app();
        initJsonLd.organization();
    }, []);

    const deviceContextValue = useMemo(() => {
        window.gamesMailRuAppConfig.isMobile = isMobile;

        return {
            isMobile, isDesktop, windowWidth, isIOS,
        };
    }, [isMobile, isDesktop, windowWidth]);

    // const shouldShowHeader = !window.isMyGamesApp && !window.isGamecenter
    // && !window.withAppShell && !window.withAppShell;

    function handleSubnavClick(e: MouseEvent<HTMLAnchorElement>) {
        e.preventDefault();

        const link = e.currentTarget.href.replace(window.location.origin, '');

        history.push(link);
    }

    const isCorp = isCorpUser(user?.user_id);

    async function handleUserGet(userData) {
        if (!isCorp) {
            return;
        }

        const newUser = {
            ...(userData?.session || {}),
            ...(userData?.profile || {}),
        };

        setUser(newUser || {});

        if (!newUser?.user_id) {
            setUnauthorized(true);

            return;
        }

        try {
            const profile = await getProfile({});

            setUser({
                ...newUser,
                isEditor: profile.is_editor,
                role: profile.ugc_role,
            });
        } catch (error) {
            // ignore
        }
    }

    return (
        <AppShell
            preset="media"
            containerType="inline-size"
            onSubnavClick={handleSubnavClick}
            onGetUser={handleUserGet}
            {...((!isCorp || window.isGamecenter) && {
                hideNav: true,
                hideFooter: true,
            })}
        >
            <AnalyticsProvider project="media" isAnalyticsAllowed={getUserConsent()}>
                <ScrollableWrapper id="gc-scrollbar">
                    <UpdateCounterProvider>
                        <DeviceContext.Provider value={deviceContextValue}>
                            <React.Suspense fallback={null}>
                                {!isCorp && !window.isGamecenter && (
                                    <div id="vkp-header">
                                        <span className="mock-subnav" />
                                    </div>
                                )}
                            </React.Suspense>
                            <div className={cn(
                                {
                                    'app-content_superapp': window.isMyGamesApp,
                                    // TODO: удалить после релиза пакета eva
                                    'app-content_temp-container': !isCorpUser(user.login),
                                    'app-content_container-query': isCorp && !window.isGamecenter,
                                    'app-content_media-query': !isCorp || window.isGamecenter,
                                },
                            )}
                            >
                                <div className="grey-mass" />
                                <ConditionalRenderer ifDesktop>
                                    {page === 'gof' ? (
                                        <Banner
                                            type="branding-gof"
                                            isDesktop
                                            isInstant
                                            withUpdateCounter
                                        />
                                    ) : (
                                        <Banner
                                            type="branding"
                                            isDesktop
                                            isInstant
                                            withUpdateCounter
                                        />
                                    )}
                                </ConditionalRenderer>
                                <Router />
                            </div>
                            <div id="vkp-footer" className="footer" />
                            <ConditionalRenderer
                                noRenderWithParam={['fromSA', 'from=newsapp', 'from=special', 'from=informer']}
                                ifMobile
                                ifNotPWA
                            >
                                <Banner
                                    type="mobile-fullscreen"
                                    isMobile
                                    isInstant
                                />
                            </ConditionalRenderer>
                            <ConditionalRenderer
                                noRenderWithParam={['fromSA', 'from=newsapp', 'from=special', 'from=informer']}
                                ifDesktop
                                ifNotPWA
                            >
                                <Banner
                                    type="fullscreen"
                                    isDesktop
                                    isInstant
                                />
                            </ConditionalRenderer>
                        </DeviceContext.Provider>
                    </UpdateCounterProvider>
                </ScrollableWrapper>
            </AnalyticsProvider>
        </AppShell>
    );
};

export default React.memo(withBanners(AppVKP));
