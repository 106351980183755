/* eslint-disable react/jsx-no-bind */
import React, {
    useState, useEffect, useRef, useCallback, Fragment, useContext, useMemo,
} from 'react';

import { useAnalyticsMethods } from '@vkplay/analytics';
import cn from 'classnames';

import DeviceContext from 'contexts/device';

import getGameCalendarList from 'src/api/v1/getGameCalendarList';
import getGameVideoList from 'src/api/v3/getGameVideoList';
import getStoryItem from 'src/api/v3/getStoryItem';
import getStoryList from 'src/api/v3/getStoryList';
import getGameBestList from 'src/api/v4/getGameBestList';
import getGameImageList from 'src/api/v4/getGameImageList';
import getGameNewsList from 'src/api/v4/getGameNewsList';
import getGamePopularList from 'src/api/v4/getGamePopularList';
import getGameStoryList from 'src/api/v4/getGameStoryList';
import getIndexList from 'src/api/v4/getIndexList';
import getNewsList from 'src/api/v4/getNewsList';
import getStoryV4List from 'src/api/v4/getStoryList';
import CustomPush from 'src/CustomPush';
import deserializeSearchParams from 'utils/deserializeSearchParams';

import Button from 'atoms/Button';

import { EditorialBannerFeed } from 'molecules/EditorialBanners';
import Paging from 'molecules/Paging';
import PagingCombined from 'molecules/PagingCombined';

import { EDITORIAL_BANNER_PLACE_STRING, MESSAGES } from './constants';
import useEditorialBannerPlaceData from './hooks/useEditorialBannerPlaceData';
import insertEditorialBannerPlaceToFeed from './utils/insertEditorialBannerPlaceToFeed';

import './feed.scss';

import type { Props } from './types';
import type { FC } from 'react';

interface ExtraData {
    search_titles?: string[];
}

// For loading best games list
const GAMES_BEST_LIST_TYPES = ['best'];

// For loading calendar games list
const GAMES_CALENDAR_LIST_TYPES = ['calendar'];

// For loading popular games list
const GAMES_POPULAR_LIST_TYPES = ['popular'];

// For loading images for game
const GAME_IMAGE_LIST_TYPES = ['image'];

// For loading videos for game
const GAME_VIDEO_LIST_TYPES = ['video'];

// For loading news for game
const GAME_NEWS_LIST_TYPES = ['news'];

// For loading stories for game
const GAME_STORY_LIST_TYPES = ['feat', 'review', 'preview', 'secret', 'files', 'faq', 'hard'];

// For loading feed on the index screen, or pages with tag filter (like industry page)
const INDEX_LIST_TYPES = ['home', 'industry', 'lifestyle'];

// For loading news feed pages
const NEWS_LIST_TYPES = ['news', 'vkp-news'];

// For loading stories feed pages
const STORY_LIST_TYPES = [
    'compilation',
    'gof',
    'video',
];

// For loading ugc materials on the dedicated page
const STORY_V4_LIST_TYPES = [
    'cybersport',
    'file',
    'review',
    'preview',
    'feat',
    'secret',
    'hard',
    'vkp-feat',
    'vkp-secret',
];

// Backend doesn't recognize custom type, like vkp-news
// or something like that, so we convert it to the origin types
const VKP_TYPES_TO_ORIGIN: Record<string, string> = {
    'vkp-news': 'news',
    'vkp-feat': 'feat',
    'vkp-secret': 'secret',
};

const getTypeFromVKP = (type: string) => {
    if (type in VKP_TYPES_TO_ORIGIN) {
        return VKP_TYPES_TO_ORIGIN[type];
    }

    return type;
};

const Feed: FC<Props> = ({
    limit, offset, paging, wideStep,
    isFirstWide, noLastItem, className,
    page, filter, channels, date, type, FeedItem,
    slug, onLoadStart, onLoadEnd, game,
    scrollToOnPaging, qLimit, params,
    reloadableParams, shouldIncrementOnLoadMore,
    isControlledPrerender, isWideStepDynamic,
    withCustomPush,
    layoutType, subtitleComponent,
    editorialBannerPlace,
}) => {
    const { pushReachGoal } = useAnalyticsMethods();

    const { isMobile } = useContext(DeviceContext);
    const [feed, setFeed] = useState([]);
    const [extraData, setExtraData] = useState<ExtraData>();
    const [currentPage, setCurrentPage] = useState(null);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [isLoaded, setLoaded] = useState(false);
    const [emptyFeed, setEmptyFeed] = useState(false);
    const [sidePages, setSidePages] = useState({
        prev: '',
        next: '/',
    });

    const [withLoadGap, setWithLoadGap] = useState(false);
    const [dynamicLimit, setDynamicLimit] = useState(limit);

    const controller = useRef(null);

    const bannerPlaceData = useEditorialBannerPlaceData({ isMobile, editorialBannerPlace });

    const fetchAnyFeed = async ({
        // eslint-disable-next-line @typescript-eslint/no-shadow
        type, page, limit, date, game, tag, tags, channels, slug, signal, game_slug,
    }) => {
        try {
            // Hack for compilation story
            // It doesn't should be here, because it's story,
            // Not a feed, event it looks like feed
            if (type === 'compilation' && slug) {
                return await getStoryItem({
                    pathParameters: {
                        type: 'compilation',
                        slug,
                    },
                    signal,
                });
            }

            if (GAMES_BEST_LIST_TYPES.includes(type)) {
                return await getGameBestList({
                    queryParameters: { page, limit },
                    signal,
                });
            }

            if (GAMES_CALENDAR_LIST_TYPES.includes(type)) {
                return await getGameCalendarList({
                    queryParameters: {
                        page, limit, date,
                    },
                    signal,
                });
            }

            if (GAMES_POPULAR_LIST_TYPES.includes(type)) {
                return await getGamePopularList({
                    queryParameters: {
                        page, limit,
                    },
                    signal,
                });
            }

            if (GAME_IMAGE_LIST_TYPES.includes(type) && game) {
                return await getGameImageList({
                    pathParameters: { slug: game },
                    queryParameters: { page, limit },
                    signal,
                });
            }

            if (GAME_VIDEO_LIST_TYPES.includes(type) && game) {
                return await getGameVideoList({
                    pathParameters: { slug: game },
                    queryParameters: { page, limit },
                    signal,
                });
            }

            if (GAME_NEWS_LIST_TYPES.includes(type) && game) {
                return await getGameNewsList({
                    pathParameters: { slug: game },
                    queryParameters: {
                        page, limit, tag,
                    },
                    signal,
                });
            }

            if (GAME_STORY_LIST_TYPES.includes(type) && game) {
                return await getGameStoryList({
                    pathParameters: { slug: game, type },
                    queryParameters: {
                        page, limit, tag,
                    },
                    signal,
                });
            }

            if (INDEX_LIST_TYPES.includes(type)) {
                return await getIndexList({
                    queryParameters: {
                        page, limit, tag,
                    },
                    signal,
                });
            }

            if (NEWS_LIST_TYPES.includes(type)) {
                return await getNewsList({
                    queryParameters: { page, limit },
                    signal,
                });
            }

            if (STORY_LIST_TYPES.includes(type)) {
                return await getStoryList({
                    pathParameters: {
                        type: getTypeFromVKP(type),
                    },
                    queryParameters: {
                        page, limit, tags, channels, game_slug,
                    },
                    signal,
                });
            }

            if (STORY_V4_LIST_TYPES.includes(type)) {
                return await getStoryV4List({
                    pathParameters: {
                        type: getTypeFromVKP(type),
                    },
                    queryParameters: {
                        page, limit, tags, channels, game_slug,
                    },
                    signal,
                });
            }
        } catch (error) {
            // ignore
        }
    };

    useEffect(() => {
        if (controller.current) {
            controller.current.abort();
        }

        if (window.AbortController) {
            controller.current = new AbortController();
        }

        load(controller.current.signal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, page, filter, channels, date, slug, game, dynamicLimit, reloadableParams]);

    useEffect(() => {
        if (!qLimit) {
            setDynamicLimit(+limit);

            return;
        }

        setDynamicLimit(+qLimit);
    }, [qLimit]);

    useEffect(() => {
        if (((paging !== 'combined'
            && paging !== 'combined-light')
            && paging !== 'button')
            || typeof currentPage !== 'number') {
            return;
        }

        setSidePages({
            prev: currentPage !== 1 ? getPageUrl(currentPage - 1) : null,
            next: currentPage !== totalPages ? getPageUrl(currentPage + 1) : null,
        });
    }, [currentPage, totalPages]);

    async function initFeed(feedData): void {
        let { results } = feedData;

        setEmptyFeed(false);

        if (feedData.games) {
            results = feedData.games;
        } else if (feedData.items) {
            // Нужно для страницы поиска на GMR
            results = feedData.items.map((item) => ({
                name: item.title,
                picture: item.icon,
                platforms: [...item.tags],
                alias: item.extra.alias,
                url: item.url,
                rating: item.extra.rating,
                date_release: item.extra.date_release,
                date_release_text: item.extra.date_release_text,
            }));
        } else if (type === 'special') {
            results = feedData.map((item, i) => feedData[feedData.length - 1 - i]); // от новых к старым
        }

        if (onLoadEnd) {
            onLoadEnd(feedData);
        }

        if (results && !results.length) {
            setEmptyFeed(true);
        }

        if (results?.length > 0) {
            setFeed(results);
        }

        setExtraData(feedData.extra);
        setTotalItems(feedData.count);
        setTotalPages(feedData.total_pages);
        setCurrentPage(+page);
        setLoaded(true);
    }

    async function addToFeed(feedData): void {
        // удаляем из подгруженного списка дубль, если он есть
        const filteredData = feedData.results.filter((item) => {
            if (item.id === feed[feed.length - 1].id) {
                setWithLoadGap(true);
                // ставим флаг, что теперь последний элемент должен рендериться (чтобы не было дыры)

                return;
            }

            return true;
        });

        setFeed([
            ...feed,
            ...filteredData,
        ]);
    }

    async function load(controllerSignal: AbortSignal) {
        const emptyArray: null[] = new Array(+qLimit || +dynamicLimit || 6).fill(null);

        setFeed(emptyArray);
        setExtraData(undefined);

        if (onLoadStart) {
            onLoadStart();
        }

        // Parsing query parameters (like &tag=industry) from provided string
        const parametersObject = deserializeSearchParams(reloadableParams || params);

        const response = await fetchAnyFeed({
            ...parametersObject,
            type,
            page,
            limit: +qLimit || +dynamicLimit,
            game,
            slug,
            date,
            channels,
            signal: controllerSignal,
        });

        initFeed(response);
    }

    function handleForceReload(): void {
        load();
    }

    async function loadMore() {
        const emptyArray: null[] = new Array(+qLimit || +dynamicLimit || 6).fill(null);

        setFeed([
            ...feed,
            ...emptyArray,
        ]);

        const nextPage = +currentPage + 1;

        // Parsing query parameters (like &tag=industry) from provided string
        const parametersObject = deserializeSearchParams(reloadableParams || params);

        const response = await fetchAnyFeed({
            ...parametersObject,
            type,
            page: nextPage,
            limit: +qLimit || +dynamicLimit,
            game,
            slug,
            date,
            channels,
        });

        addToFeed(response);

        setCurrentPage(nextPage);

        if (shouldIncrementOnLoadMore) {
            setPageInSearch(nextPage);
        }

        pushReachGoal({
            params: {
                action: 'showmore_click',
            },
        });
    }

    function getPageUrl(newPage) {
        const pathname = window.location.pathname.replace('/gamecenter', '');

        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('page', newPage);

        return `${pathname}?${searchParams.toString()}`;
    }

    function setPageInSearch(newPage) {
        const newRelativePathQuery = getPageUrl(newPage);
        // eslint-disable-next-line no-restricted-globals
        history.pushState(null, '', newRelativePathQuery);
    }

    function getWide(i): boolean {
        if (i === 0 && isFirstWide) {
            return true;
        }

        if (wideStep) {
            const modifier = 1;

            if (isWideStepDynamic) {
                if (!isFirstWide) {
                    if (i === 0 || i === wideStep) {
                        return false;
                    }

                    return offset === wideStep || (i - offset) % wideStep === 0;
                }

                return i === wideStep || i % wideStep === 0;
            }

            return (i + modifier) % wideStep === 0;
        }

        return false;
    }

    const getMemoWide = useCallback((i) => getWide(i), []);

    const noPaging = paging === 'none';
    const isButtonPaging = paging === 'button';
    const isClassicPaging = paging === 'classic';
    const isCombinedPaging = paging === 'combined' || paging === 'combined-light';

    const { withBanner, feed: feedWithBanner } = useMemo(
        () => insertEditorialBannerPlaceToFeed(feed, bannerPlaceData.place),
        [feed, bannerPlaceData.place],
    );

    if (isLoaded && emptyFeed) {
        return <h2 className="strip__empty">{MESSAGES.ru.nothing}</h2>;
    }

    const SubtitleComponent = subtitleComponent;

    return (
        <>
            {SubtitleComponent
                ? <SubtitleComponent data={{ ...extraData, totalItems }} />
                : null}
            <section className={cn('feed', {
                feed_static: noPaging,
                'feed_layout-grid': layoutType === 'grid',
                className,
            })}
            >
                {feedWithBanner && feedWithBanner.map((item, i) => {
                    if (item === EDITORIAL_BANNER_PLACE_STRING) {
                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <EditorialBannerFeed key={`banner_${i}`} type={bannerPlaceData.type} />
                        );
                    }

                    const bannerOffset = 1;
                    const feedLengthWithoutBanner = feed.length - bannerOffset;
                    const indexWithoutEditorialBanner = withBanner && i > (bannerPlaceData.place || 0)
                        ? i - bannerOffset
                        : i;

                    const dynamicLimitWithoutEditorialBanner = withBanner && i > (bannerPlaceData.place || 0)
                        ? dynamicLimit - bannerOffset
                        : dynamicLimit;

                    const notLastElement = indexWithoutEditorialBanner < feedLengthWithoutBanner;
                    const shouldRender = (indexWithoutEditorialBanner >= offset) && (
                        !noLastItem
                            || (notLastElement
                                || withLoadGap
                                || feedLengthWithoutBanner < dynamicLimitWithoutEditorialBanner)
                    );

                    if (!shouldRender) {
                        return null;
                    }

                    if (withCustomPush && indexWithoutEditorialBanner === 2) {
                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <Fragment key={indexWithoutEditorialBanner}>
                                <CustomPush key="push-custom" isStatic isActive />
                                <FeedItem
                                    /* eslint-disable-next-line react/no-array-index-key */
                                    key={indexWithoutEditorialBanner}
                                    index={indexWithoutEditorialBanner}
                                    data={item}
                                    feedType={type}
                                    isWide={getMemoWide(indexWithoutEditorialBanner)}
                                    noLinkAuthor
                                />
                            </Fragment>
                        );
                    }

                    return (
                        <FeedItem
                            /* eslint-disable-next-line react/no-array-index-key */
                            key={indexWithoutEditorialBanner}
                            index={indexWithoutEditorialBanner}
                            data={item}
                            feedType={type}
                            isWide={getMemoWide(indexWithoutEditorialBanner)}
                            noLinkAuthor
                        />
                    );
                })}
                {isClassicPaging && (
                    <Paging current={+currentPage} limit={limit} totalItems={totalItems} scrollTo={scrollToOnPaging} />
                )}
                {isButtonPaging && !!sidePages.next && (
                    <div className="feed__more-wrap">
                        <Button
                            className="feed__more"
                            onClick={loadMore}
                            label={MESSAGES.ru.moar}
                            variant="outline-primary"
                        />
                    </div>
                )}
                {isCombinedPaging && feed?.length && totalPages > 1 && (
                    <PagingCombined
                        currentPage={+currentPage}
                        startingPage={page}
                        loadMore={loadMore}
                        totalItems={totalItems}
                        totalPages={totalPages}
                        itemsLimit={dynamicLimit}
                        setLimit={setDynamicLimit}
                        scrollTo={scrollToOnPaging}
                        sidePages={sidePages}
                        forceReload={handleForceReload}
                        noLimitControls={paging === 'combined-light'}
                    />
                )}
            </section>
            { isControlledPrerender && isLoaded && <div id="prerenderAnchor" /> }
        </>
    );
};

Feed.defaultProps = {
    // eslint-disable-next-line react/default-props-match-prop-types
    offset: null,
    // eslint-disable-next-line react/default-props-match-prop-types
    paging: 'classic',
    // eslint-disable-next-line react/default-props-match-prop-types
    page: 1,
    // eslint-disable-next-line react/default-props-match-prop-types
    tabs: [],
};

export default Feed;
