import React from 'react';

import { useAnalyticsMethods } from '@vkplay/analytics';
import cn from 'classnames';

import { singular, defaultCover } from 'aliases';

import CustomLink from 'atoms/CustomLink';
import Picture from 'atoms/Picture';
import Time from 'atoms/Time';

import { relativize } from 'neurons';

import { ReactComponent as CommentsSvg } from 'img/comments.svg';

import 'scss/skin/feed-story.scss';

const currentYear = new Date().getFullYear();

const Incarnate: React.FC = ({
    isWide, isMini, isOuter, noLinkAuthor, feedType,
    data: {
        alias, author, name, title, picture, url,
        postsCount,
        lead_pic: leadPic,
        date_published: datePublished,
        published_at: publishedAt,
        created_at: createdAt,
        date_added: dateAdded, // TODO: привести выдачу в профиле и других лентах к одному виду
    },
}) => {
    const { pushReachGoal } = useAnalyticsMethods();

    function handleClick(e): void {
        const { href } = e.currentTarget;

        pushReachGoal({
            params: {
                action: 'article_click',
                additionalData: href,
            },
        });
    }

    return (
        <article className={cn(
            'feed-story',
            {
                'feed-story_wide': isWide,
                'feed-story_mini': isMini,
            },
        )}
        >
            <CustomLink
                className="feed-story__top"
                to={(url && url.includes('//') ? relativize(url) : (!isOuter && url))}
                isOuterLink={isOuter}
                onClick={handleClick}
            >
                <Picture
                    classNameWrapper="feed-story__picture-wrapper"
                    className="feed-story__picture"
                    src={picture || leadPic || defaultCover[alias || feedType]}
                    height={isWide ? 310 : 182}
                    alt={name || title}
                />
                <p className="feed-story__type">
                    <span className="feed-story__type-label">{singular.ru[alias || feedType]}</span>
                </p>
                <p className="feed-story__title">{name || title || 'Без заголовка'}</p>
            </CustomLink>
            <div className="feed-story__bottom">
                <Time
                    time={datePublished || dateAdded || publishedAt || createdAt}
                    noYear={new Date(datePublished || dateAdded || publishedAt).getFullYear() >= currentYear}
                    dark
                    relative
                />
                {author && (
                    <address className="feed-story__author-wrap">
                        {noLinkAuthor ? (
                            <span
                                className="feed-story__author"
                            >
                                {author.nick || author.name}
                            </span>
                        ) : (
                            <a
                                className="feed-story__author"
                                href={author.url}
                            >
                                {author.nick || author.name}
                            </a>
                        )}
                    </address>
                )}
                {!!postsCount && (
                    <CustomLink
                        to={`${url}#comments`}
                        className="feed-story__comments"
                        isOuterLink={isOuter}
                    >
                        <CommentsSvg className="feed-story__comments-icon" />
                        {postsCount}
                    </CustomLink>
                )}
            </div>
        </article>
    );
};

export default Incarnate;
