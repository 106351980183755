import { useEffect, useContext } from 'react';

import { useAnalytics, useAnalyticsMethods } from '@vkplay/analytics';
import { isCorpUser } from '@vkplay/shared';
import { useHistory } from 'react-router-dom';

import AuthContext from 'contexts/auth';

import getProfile from 'src/api/v3/getProfile';
import { LEELA_PATH } from 'src/constants';
import { langsAvailable } from 'utils/getLang';
import { setScriptTag } from 'utils/scriptTagManager';

import type React from 'react';

const IDS_TO_SPA_REDIRECT = [
    'ph-menu-media',
    'ph-menu-media-all',
    'ph-menu-media-news',
    'ph-menu-media-articles',
    'ph-menu-media-secret',
    'ph-menu-media-industry',
    'ph-menu-media-mods',
    'ph-menu-media-gamedb',
    'ph-menu-media-gof',
    'ph-menu-mobile-media',
    'ph-menu-mobile-media-all',
    'ph-menu-mobile-media-news',
    'ph-menu-mobile-media-articles',
    'ph-menu-mobile-media-secret',
    'ph-menu-mobile-media-industry',
    'ph-menu-mobile-media-mods',
    'ph-menu-mobile-media-gamedb',
    'ph-menu-mobile-media-gof',
];

const Navigation: React.FC = () => {
    const { setIsAnalyticsAllowed } = useAnalytics();
    const { pushReachGoal } = useAnalyticsMethods();
    const { user, setUser, setUnauthorized } = useContext(AuthContext);
    const history = useHistory();

    useEffect(() => {
        const isCorp = isCorpUser(user?.user_id);

        if (isCorp) {
            return;
        }

        initHeaderAndFooter();
    }, [user]);

    async function handleUserGet(newUser) {
        setUser(newUser || {});

        if (!newUser?.user_id) {
            setUnauthorized(true);

            return;
        }

        try {
            const profile = await getProfile({});

            setUser({
                ...newUser,
                isEditor: profile.is_editor,
                role: profile.ugc_role,
            });
        } catch (error) {
            // ignore
        }
    }

    async function initHeaderAndFooter(): void {
        // const data = await Backend.api_get('/social/profile/v2/session');

        if (window.isMyGamesApp || window.isGamecenter) {
            return;
        }

        const GEMS = window.__GEMS || {};

        const { lang } = window.__GEMS;
        const isRuLang = lang === 'ru_RU';

        window.__GEMS = {
            ...GEMS,
            auth: 1,
            elem: '#vkp-header',
            elemFooter: '.footer',
            enable_analytics: true,
            hide_adv_popup: window.isSTB,
            onGetUser: handleUserGet,
            on_user_decision_cookiebot: setIsAnalyticsAllowed,
            toolbar: {
                preset: 'store',
                currentItem: 'ph-menu-media',
                onItemsClick: (event: PointerEvent, id: string, href: string): void => {
                    if (IDS_TO_SPA_REDIRECT.includes(id)) {
                        event.preventDefault();

                        const url = new URL(href.startsWith('http') ? href : `https:${href}`);

                        history.push(url.pathname);
                    }
                },
            },
            langs_available: langsAvailable,
            subNavigation: {
                history,
                items: [
                    {
                        title: 'stories',
                        href: '/',
                        isExact: true,
                    },
                    {
                        title: 'mediaNews',
                        href: '/news/',
                    },
                    ...(isRuLang ? [
                        {
                            title: 'articles',
                            href: '/articles/feat/',
                        },
                        {
                            title: 'secrets',
                            href: '/articles/secret/',
                        },
                        {
                            title: 'gameIndustry',
                            href: '/industry/',
                        },
                        {
                            title: 'mods',
                            href: '/articles/file/',
                        },
                        {
                            title: 'gameDB',
                            href: '/games/',
                        },
                    ] : []),
                ],
                onItemsClick: (href: string): void => {
                    history.push(href);

                    pushReachGoal({
                        params: {
                            action: 'header_click',
                            additionalData: href,
                        },
                    });
                },
            },
        };

        setScriptTag({
            id: 'header-script',
            src: `${LEELA_PATH}/header.js`,
        });

        setScriptTag({
            id: 'footer-script',
            src: `${LEELA_PATH}/footer.js`,
        });
    }

    return null;
};

export default Navigation;
