const getUserConsent = () => {
    if (!window.__cmp) {
        return true;
    }

    const consentExist = window.__cmp('consentStatus')?.consentExists;
    const userChoiceExists = window.__cmp('consentStatus')?.userChoiceExists;

    // c52 marketing cookie purpose
    const marketingCookie = window.__cmp('getCMPData')?.purposeConsents?.c52;

    return !!(consentExist && userChoiceExists && marketingCookie);
};

export default getUserConsent;
